<template>
    <div id="tu-user-agreement">
        <el-dialog v-model="dialogVisible" title="用户协议" destroy-on-close fullscreen>
            <div class="contract">
                <div class="head">
                    <h1 id="toc_0">快代理用户服务协议</h1>
                    <p class="update_time">更新时间：2022-04-08</p>
                </div>
                <div class="main">
                    <p class="bold">欢迎您使用快代理服务！</p>
                    <p class="bold">本协议是快代理网站（<a href="http://www.kuaidaili.com" target="_blank">www.kuaidaili.com</a>以下称为“本网站”）的经营者积善科技（北京）有限公司（以下简称为“快代理”），与用户（下称为“您”），就您使用快代理服务的相关事项签订的协议。</p>
                    <p class="bold">为保障您的合法权益及更好的体验快代理服务，您应当充分阅读、理解并同意本协议，其中限制、免责条款或者其他涉及您重大权益的条款（如违约处罚、争议管辖等）可能会以加粗、加下划线等形式提示您重点注意。</p>
                    <p class="underline bold">除非您已充分阅读、理解并同意接受和遵守本协议，否则，请您不要使用快代理服务。您可以通过网页确认或快代理认可的其他任何方式表示接受本协议，或您以任何方式使用快代理服务的，即视为您已阅读并同意接受本协议及其组成附件（包括但不限于隐私保护政策、网页公示信息等）的约束，本协议即在您与快代理之间产生法律效力，双方应遵守协议约定合理合法使用快代理服务及产品。</p>
                    <p class="bold">使用快代理服务应当遵守本协议及其服务规则，包括但不限于隐私政策、操作规范等，服务规则以快代理官网及其相关页面所展示的产品、服务内容以及向用户实际提供的为准。您应事先了解服务规则，并根据服务规则进行操作，以确保顺利使用。</p>

                    <h2 id="toc_1">1、总则</h2>
                    <p>1.1 快代理服务指快代理提供高品质的代理云服务，包括HTTP、HTTPS、Socks代理，赋能于大规模数据采集（具体以快代理实际提供的为准），您可以根据自己的需求选择使用一项或多项具体服务并遵守其服务规则。</p>
                    <p class="underline bold">1.2 涉及具体服务的服务内容、技术规范、操作文档、计费标准等内容的服务条款、规则、说明、标准等（统称为“服务规则”），以快代理官网或其他相关页面展示的内容为准，并构成本协议的重要组成部分，与本协议具有同等法律效力。</p>
                    <p>1.3 快代理是中立的技术服务提供者，依本服务协议向您提供各类技术产品和服务；您所有的的网站、应用、软件、平台等发布的任何产品、服务及相关内容等，由您自行运营并承担全部法律责任，快代理不对您的行为承担任何责任，也不对您的行为提供任何形式层面或实质层面的保证等。</p>
                    <p>1.4 本服务协议双方均保证已经依照中国法律法规等要求的相关规定获得了合法经营资质或政府审批手续等，有权依法运营其产品及服务。双方进一步保证，在本协议有效期内持续保持具备国家相关规定要求的经营资质或审批手续。</p>
                    <p>1.5 您确认：您在使用快代理服务及产品之前，已经充分阅读、理解并接受本服务协议及其组成部分文件的全部内容（特别是本服务协议中以加粗及/或下划线标注的内容），一旦您选择“同意”并完成注册流程或使用快代理服务及其产品，即表示您同意遵循本服务协议及其组成部分文件之所有约定。</p>
                    <p>1.6 您同意：快代理有权随时对本服务条款及相应的服务规则内容进行单方面的变更，并有权以消息推送、网页公告等方式予以公布，而无需另行单独通知您；若您在本服务条款内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的条款内容使用本服务，若修订后的条款与修订之前的条款有冲突的，以修订后的条款为准；若您不同意修改后的服务条款，您应立即停止使用本服务。</p>

                    <h2 id="toc_2">2、账户</h2>
                    <p>2.1 注册</p>
                    <p>2.1.1 注册者资格</p>
                    <p>2.1.1.1 您确认，在您完成注册程序或以其他快代理允许的方式实际使用本服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下统称为“法律主体”）。</p>
                    <p class="underline bold">2.1.1.2 若您是未成年人或限制民事行为能力人，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果，包括但不限于快代理中止服务、解除本服务协议等，且快代理有权注销（永久冻结）您的账户，若因您的不当注册行为给快代理及其关联方造成损失，快代理有权向您及您的代理人索赔，并要求您和您的代理人支付快代理及其关联方为维护自身权益所支出的合理费用，包括但不限于诉讼费、律师费等，并向您及您的监护人索偿。若因您的不当注册行为以及不当使用快代理服务行为给第三方造成损失或侵犯第三方合法权益，第三方有权向您和您的法定代理人索赔，快代理不对此承担任何责任。</p>
                    <p>2.1.2 注册、账户和实名认证</p>
                    <p>2.1.2.1 在您按照注册页面提示填写信息、阅读并同意本服务条款且完成全部注册流程后，或在您按照激活页面提示填写信息、阅读并同意本服务条款且完成全部激活程序后，或您以其他快代理允许的方式实际使用本网站服务时，您即受本服务协议及其组成文件约束。您可以使用您提供或确认的邮箱、手机号码或者快代理允许的其它方式作为登录手段进入快代理网站。</p>
                    <p>2.1.2.2 您了解并同意，您在快代理网站完成的注册流程并注册成功后，您即获得快代理账户。</p>
                    <p>2.1.2.3 目前快代理允许一个法律主体拥有多个快代理账户，但每个快代理账户仅能对应唯一的法律主体。除法律、生效法律文书或符合快代理公布的转让、赠与、继承条件外，您不得以任何方式转让、赠与或让他人继承您的快代理账户。同时，在进行符合条件的快代理账户转让、赠与或继承时，快代理有权要求您、及/或受让受赠者、或您的继承人提供真实有效的文件材料并按照快代理要求的操作流程办理。</p>
                    <p>2.1.2.4 您了解并同意，为保证账户和交易安全，您应在注册时及时进行实名认证，若您未进行实名认证，快代理有权随时要求您完成快代理账户的实名认证；同时，快代理可能会就某些产品或服务的开通，要求您提供服务所需要的相应的身份资料和信息，做进一步的身份认证或资格验证，您的账户只有在通过这些认证和验证之后，方可使用相关产品或服务。</p>
                    <p>2.1.2.5 通常情况下，您的快代理账户是您在本网站进行一切活动的唯一身份识别依据，每一个快代理账户都可以在本网站独立开展活动。但在下列情形下，快代理有权根据自己的判断，对同一及/或关联法律主体拥有的多个快代理账户进行统一处理措施，包括但不限于关闭账号、中止服务等：</p>
                    <p>2.1.2.5.1 多个快代理账户之间存在主账户与子账户、授权账户与被授权账户等关联关系，且根据法律法规、本服务条款、快代理各产品条款或其他快代理规则的规定，各账户之间互相承担连带责任的；</p>
                    <p>2.1.2.5.2 多个快代理账户使用同一实名认证，且其中两个或两个以上快代理账户同时存在恶意欠费（在欠费通知送达之日起三日内未补缴费用）及/或违反法律法规、本服务条款、快代理各产品条款或其他快代理规则的行为的；</p>
                    <p>2.1.2.5.3 多个快代理账户绑定同一支付宝或微信账户付款，且其中两个或两个以上快代理账户同时存在恶意欠费及/或违反法律法规、本服务条款、快代理各产品条款或其他快代理规则的行为的</p>
                    <p>2.1.2.5.4 多个快代理账户之间存在一项或多项注册信息相同、代为付款、购买的产品或服务用于同一目的，或其他关联情形，并存在恶意欠费及/或违反法律法规、本服务条款、快代理各产品条款或其他快代理规则的行为，且快代理通过结合其他相关证据足以判断上述快代理账户实际属于同一法律主体或同一团体的；</p>
                    <p>2.1.2.5.5 其他快代理有充足理由需要对多个快代理账户进行统一处理的情形。</p>
                    <p>2.1.3 信息</p>
                    <p>2.1.3.1 在完成注册或激活流程时，您应当按照法律法规要求，按相应页面的提示准确提供并及时更新您的资料，以使之真实、及时，完整和准确。如快代理有合理理由怀疑您提供的资料错误、不实、过时或不完整的，快代理有权向您发出询问及/或发送改正的通知，并有权直接做出删除相应资料的处理，直至中止、终止对您提供部分或全部服务。快代理对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。</p>
                    <p>2.1.3.2 您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便快代理或其他会员与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用快代理服务过程中产生任何损失或增加费用的，应由您完全独自承担。您了解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应及时通知快代理并按快代理的要求进行操作，同时，您在注册或更新后的联系方式（包括但不限于电话号码、微信号、邮箱等）可以作为发生纠纷时快代理向您送法相关文件的有效地址，并作为有效的司法送达地址使用，若因您自身原因未提供准确或及时更新联系方式导致的送达失败，其后果由您自行承担。</p>
                    <p>2.2 账户安全</p>
                    <p>2.2.1 <span class="underline bold">您须自行负责对您的快代理账户和密码保密，且须对您在该登录名和密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。</span>您同意：如发现任何人未经授权使用您的快代理账户和密码，或发生违反保密规定的任何其他情况，您会立即通知快代理，并及时采取合理措施，包括修改密码等；同时确保您在每个上网时段结束时，以正确步骤离开网站。快代理不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解快代理对您的请求采取行动需要合理时间，快代理对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</p>
                    <p>2.2.2 除法律另有规定或生效法律文书确认，且征得快代理的同意，否则，您的快代理账户和密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外），因您自身账号保管不善或私自进行账号的转让、赠与或继承导致的后果有您自行承担，快代理概不负责。</p>
                    <p class="underline bold">2.2.3 您理解并同意不将快代理的服务用于违法犯罪活动，快代理有权了解您使用本网站产品及服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息；如果快代理有合理理由怀疑您提供的信息不真实、您进行虚假交易，或您的行为违反快代理的网站规则的，或将快代理服务用于违法犯罪的，快代理有权暂时或永久限制您账户下所使用的所有产品及/或服务的部分或全部功能。</p>
                    <p class="underline bold">2.2.4 您理解并同意，快代理有权按照国家司法、行政、军事、安全等机关（包括但不限于公安机关、检察机关、法院、海关、税务机关、安全部门等）的要求对您的个人信息及在本网站的交易及账户等进行查询或冻结。</p>
                    <p>2.3 账户注销</p>
                    <p>2.3.1 快代理有权在您违反法律法规规定或违反本服务条款的情况下，中止或终止为您提供部分或全部服务、直至注销快代理账户，及解除本服务协议。</p>
                    <p>2.3.2登录名的注销</p>
                    <p>2.3.2.1 您理解并同意，如您连续12个月未使用您的邮箱、手机或本网站认可的其他方式和密码登录过本网站，且您的账户下不存在任何未到期的服务，快代理有权注销您的登录名，您将不能再登录快代理网站，若您需要再次使用快代理服务或产品，您须重新注册并进行实名认证。</p>

                    <h2 id="toc_3">3、网站服务使用守则</h2>
                    <p>为有效保障您使用本服务的合法权益，您理解并同意接受以下规则：</p>
                    <p>3.1 您通过包括但不限于以下方式向快代理发出的指令，均视为您本人的指令，指令不可撤回或撤销，您应自行对快代理执行前述指令所产生的任何结果承担责任，快代理不对该指令引起所产生的任何法律后果承担任何责任；</p>
                    <p>3.1.1 您同意并接受您通过您的快代理账户和密码进行的所有操作均是您个人意志，与快代理无关，快代理无需对您的操作承担任何责任；</p>
                    <p>3.1.2 您同意并接受与您的账号绑定的手机号码向快代理发送的全部信息；</p>
                    <p>3.1.3 您同意并接受与您的账号绑定的其他硬件、终端、软件、代号、编码、代码、其他账户名等有形体或无形体向快代理发送的信息；</p>
                    <p>3.1.4 其他快代理与您约定或快代理认可的其他方式。</p>
                    <p class="underline bold">3.2 您在使用本服务过程中，本服务条款内容、页面上出现的关于交易操作的提示或快代理发送到您手机的信息（短信或电话等）内容是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。您了解并同意快代理有权单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示（或发送到该手机的短信或电话等）为准，您同意并遵照服务规则是您使用本服务的前提。</p>
                    <p class="underline bold">3.3 快代理可能会以电子邮件（或发送到您手机的短信或电话等）方式通知您服务进展情况以及提示您进行下一步的操作，快代理推送的信息发送到您确定联系方式（包括但不限于电话号码、微信号、邮箱等）的任一系统中即视为送达，若因联系方式变更您未及时更新或通知快代理造成未收到相关通知的，该不利后果由您自行承担。但快代理不保证您能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且不对此承担任何后果。因此，在服务过程中您应当及时登录到本网站查看和进行交易操作。因您没有及时查看和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，快代理不负任何责任。</p>
                    <p>3.4 您授权快代理可以通过向第三方审核您的身份和资格，取得您使用本服务的相关资料。</p>
                    <p>3.5 在您开始使用快代理的某一产品或服务前，可能需要和快代理就这一产品或服务签订单独的服务协议。您只有在接受该服务协议的全部内容后方可使用该产品或服务；如您不同意该服务协议的部分或者全部的，请您不要进行后续操作。</p>
                    <p>3.6 在您使用快代理服务时，您需要向快代理按照相应产品或服务的收费介绍、订单或相关协议支付服务费。快代理拥有制订及调整服务费之权利，具体服务费用以您使用本服务时页面上所列之收费方式公告或您与快代理达成的其他书面协议为准。</p>
                    <p class="underline bold">3.7 申诉及处理</p>
                    <p class="underline bold">3.7.1 在您使用快代理服务的过程中，有可能因为存在本服务条款第5.4条所列情形之一，而被快代理采取了包括但不限于停止全部或部分服务、限制服务的全部或部分功能、冻结全部或部分账户资金等措施，您可以通过邮件、站内信、短信或电话等方式向快代理按照相应的程序进行申诉。</p>
                    <p class="underline bold">3.7.2 您通过申诉程序，向快代理申请解除上述限制或冻结或恢复服务的，应按照快代理的要求，如实提供身份证明及相关资料，以及快代理要求的其他信息或文件，以便快代理进行核实。您应充分理解您的申诉并不必然被允许，快代理有权依照自行判断来决定是否同意您的申诉请求。</p>
                    <p class="underline bold">3.7.3 您理解并同意，如果您拒绝如实提供身份证明及相关资料的，或申诉未能通过快代理审核的，快代理有权继续冻结该等账户且继续限制该等产品或者服务的部分或全部功能，至您提供解除冻结措施快代理所要求的全部文件。</p>
                    <p>3.8 关于第三方</p>
                    <p>3.8.1您在使用快代理服务或产品过程中涉及获取使用来自第三方的任何产品或服务，您还可能受限于该等第三方的相关条款和条件，您需按照第三方的要求与其建立服务关系，该服务关系与快代理无关，快代理对此不予过问亦不承担任何责任，本服务条款不影响您与该第三方的法律关系。</p>

                    <h2 id="toc_4">4、 您的权利和义务</h2>
                    <p>4.1 您有权利享受快代理提供的互联网技术和信息服务，并有权利在接受快代理提供的服务时获得快代理的技术支持、咨询等服务，服务内容详见本网站相关产品介绍。</p>
                    <p>4.2 您应尊重快代理及其他第三方的知识产权和其他合法权利，并同意在发生侵犯上述权益的违法事件时快代理优先尽力保护快代理及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失；快代理保留您侵犯快代理合法权益时终止向您提供服务并不退还任何款项的权利。因您的侵权行为或其他不当行为给快代理及其关联方造成任何损失的，快代理有权向您追偿，追偿范围包括但不限于损失、维护权利的支出费用等。</p>
                    <p>4.3 对由于您向快代理提供的联络方式有误以及您用于接受快代理邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到快代理的相关通知而导致的后果和损失。</p>
                    <p class="underline bold">4.4您保证：</p>
                    <p class="underline bold">4.4.1.您使用快代理产品或服务时将遵从法律法规、行业惯例和社会公共道德，不会利用快代理提供的服务进行存储、发布、传播如下信息和内容：违反国家法律法规政策的任何内容（信息）；违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；妨碍互联网运行安全的信息；进行钓鱼、黑客、网络诈骗等违法犯罪行为，涉嫌传播计算机病毒、木马、恶意代码等，或对其他网站、主机进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DOS等；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容;您同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等;</p>
                    <p class="underline bold">4.4.2. 使用快代理产品或服务时，应遵守您与快代理签订的服务条款、快代理在www.kuaidaili.com相关页面上的管理规范、流程、服务说明、价格说明和您确认同意的订购页面的内容，包括但不限于：</p>
                    <p class="underline bold">4.4.2.1. 您应按时付款；</p>
                    <p class="underline bold">4.4.2.2. 不应出现任何破坏或试图破坏网络安全的行为等；</p>
                    <p class="underline bold">4.4.3. 您以及您的关联方不是任何国家，国际组织或者地域实施的贸易限制，制裁或者其他法律限制的对象。</p>
                    <p class="underline bold">4.4.4.您保证不会利用技术或其他手段破坏或扰乱本网站。</p>
                    <p>如您违反上述保证，快代理除有权根据相关服务条款采取删除信息、中止服务、终止服务的措施，并有权限制您账户如新购产品或服务、续费等部分或全部功能，如因您上述行为给快代理造成任何损失的，您应予赔偿。</p>

                    <h2 id="toc_5">5、 快代理的权利和义务</h2>
                    <p>5.1 快代理应根据您选择的服务以及交纳款项的情况向您提供合格的网络技术和信息服务。</p>
                    <p>5.2 快代理承诺对您资料采取保密措施，不向第三方披露您资料，不授权第三方使用您资料，除非：</p>
                    <p>5.2.1 依据本服务条款或者您与快代理之间其他服务协议、合同、在线条款等规定可以提供；</p>
                    <p>5.2.2 依据法律法规的规定应当提供；</p>
                    <p>5.2.3 行政、司法等职权部门要求快代理提供；</p>
                    <p>5.2.4 您同意快代理向第三方提供；</p>
                    <p>5.2.5 快代理解决举报事件、提起诉讼而提交的；</p>
                    <p>5.2.6 快代理为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；</p>
                    <p>5.2.7 快代理为向您提供产品、服务、信息而向第三方提供的，包括快代理通过第三方的技术及服务向您提供产品、服务、信息的情况。</p>
                    <p>5.2.8 其他。</p>
                    <p>5.3 快代理有权根据本服务条款的约定，根据您的服务使用情况，自您的账户余额中直接扣除相关服务费用。</p>
                    <p class="underline bold">5.4 当存在如下情形之一时，快代理有权对您名下账户资金的部分或全部进行冻结：</p>
                    <p class="underline bold">5.4.1 生效法律文书要求的；</p>
                    <p class="underline bold">5.4.2 国家有权机关要求进行冻结的；</p>
                    <p class="underline bold">5.4.3 您使用快代理服务的行为涉嫌违法犯罪、侵害第三方合法权益的；</p>
                    <p class="underline bold">5.4.4 快代理基于单方面合理判断，认为您的账户操作、资金进出等存在异常时；</p>
                    <p class="underline bold">5.4.5 他人向您账户错误汇入资金等导致您可能存在不当得利的；</p>
                    <p class="underline bold">5.4.6 您遭到他人投诉， 且对方已经提供了基础证据的；</p>
                    <p class="underline bold">5.4.7 快代理根据自行合理判断，认为可能产生风险的其他情况。</p>
                    <p class="underline bold">快代理按照前述规定对您的账户资金进行冻结后，将通过邮件、站内信、短信或电话等方式通知您按照相应的程序进行申诉。如果您按照本服务条款第3.7条的要求进行申诉并经快代理审核通过的，快代理将立即解除资金冻结措施；如果您未在规定时间内进行申请，或您的申诉未能通过快代理审核的，则您确认快代理有权继续冻结该资金，或将相关冻结款项按原路退回该笔资金的充值来源账户（包括但不限于银行账户、支付宝账户等），国家机关（包括但不限于行政机关、司法机关等）要求冻结资金的除外。</p>

                    <h2 id="toc_6">6、 隐私及其他个人信息的保护</h2>
                    <p>一旦您同意本服务条款或使用本服务，您即同意快代理按照以下条款来使用和披露您的个人信息。</p>
                    <p>6.1 登录名和密码</p>
                    <p>在您注册帐户时，快代理会要求您设置快代理账户登录名和密码来识别您的身份，并完成手机号验证及邮箱激活流程，以便在您丢失密码时用以确认您的身份。您仅可通过您设置的密码来使用该账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。该账户和密码因任何原因受到潜在或现实危险时，您应该立即和快代理取得联系，在快代理采取行动前，快代理对此已经发生的损失不负任何责任。在快代理采取相应措施后，因您故意或过失导致的扩大损失快代理不承担任何责任。</p>
                    <p>6.2 银行账户信息</p>
                    <p>若快代理所提供的服务需要您提供您的银行账户信息，在您提供相应信息后，快代理将严格履行相关保密义务。</p>
                    <p>6.3 登录记录</p>
                    <p>为了保障您使用本服务的安全以及不断提升服务质量，快代理将记录并保存您登录和使用本服务的相关信息，但快代理承诺不将此类信息提供给任何第三方（除双方另有约定或法律法规另有规定及快代理关联公司外）。</p>
                    <p>6.4 广告</p>
                    <p>快代理会对快代理用户的身份数据进行综合统计，并出于提升快代理服务质量及提高用户体验的目的向您推送广告，若您不需要此类广告信息的推送，可联系快代理关闭广告信息推送。</p>
                    <p>6.5 外部链接</p>
                    <p>本网站含有到其他网站的链接，但快代理对其他网站的隐私保护政策及采取的信息保护措施不负任何责任，也不对其他网站的服务提供任何形式层面或实质层面上的保证等。快代理可能在任何需要的时候增加商业伙伴或共用品牌的网站。</p>
                    <p>6.6 安全</p>
                    <p>快代理仅按现有技术提供相应的安全措施来使快代理掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其他服务器备份数据和对用户密码加密。尽管快代理已采取相应的信息保护措施，但快代理不保证这些信息的绝对安全。因技术进步导致网站收集的信息被篡改、泄露等事件，快代理在其现有技术条件下已采取相应合理措施时，可免除快代理的责任。</p>
                    <p>6.7. 用户信息</p>
                    <p>您完成账户注册或激活账户时，应向快代理提供包括但不限于您的真实姓名、地址、国籍、电话号码和电子邮箱等信息。您还可以选填相关附加信息（包括但不限于提供您的 qq、代理用途等）。您同意本协议时即表明您同意快代理，及其各自代表和代理可为运作本服务或第6.1至6.7条的目的，合理提出之各项目的而收集、使用或披露和互相分享您的个人资料，并将这些个人信息披露给授权服务提供商和相关第三方。</p>
                    <p>您进一步理解并同意，在同意本协议时或在您将您的个人信息提交至快代理时，您即将您的数据自您的所在国家转移至中国大陆，而且该等转移是为履行您和快代理所订立之协议或为您提供您所要求之服务的必要步骤。</p>
                    <p>6.8． 您进一步同意快代理：</p>
                    <p>6.8.1. 向您发送定制化的信息或新产品/服务信息，通过电邮、电话（包括语音电话、传真或信息，如果您表明我们可以以此方式联系您）；</p>
                    <p>6.8.2. 出于为您提供增强或分析的统计功能使用您的个人信息，或者出于销售或鼓励的目的使用或披露个人信息。</p>

                    <h2 id="toc_7">7、 系统中断或故障</h2>
                    <p>系统可能因下列状况无法正常运作，使您无法使用各项互联网服务时，快代理不承担损害赔偿责任，该状况包括但不限于：</p>
                    <p>7.1 快代理在系统停机维护期间。</p>
                    <p>7.2 电信设备出现故障不能进行数据传输时。</p>
                    <p>7.3 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成快代理系统障碍不能执行业务的。</p>
                    <p>7.4 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</p>
                    <p>7.5其他。</p>

                    <h2 id="toc_8">8、 责任范围及责任限制</h2>
                    <p>8.1 快代理仅对本服务条款中列明的责任范围承担责任。</p>
                    <p>8.2 通过快代理平台与第三方建立服务关系，第三方所提供之服务品质及内容由该合作单位自行负责。</p>
                    <p>8.3 您了解并同意，因您使用本服务、违反本服务条款或在您的账户下采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引起快代理及其关联公司、员工、客户和合作伙伴被第三方索赔的，或被生效法律文书确定快代理及其关联方承担赔偿责任的，快代理及其关联方在履行完毕生效法律文书确定的义务后有权向您追偿，并要求您支付为维护合法权益所支出的费用。</p>
                    <p>8.4 在法律允许的情况下，快代理对于与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本服务条款的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本服务条款规定的排他性救济没有达到其基本目的，也应排除快代理对上述损失的责任。</p>
                    <p>8.5 除本服务条款另有规定或快代理与您就某一具体产品及/或服务另有约定外，在任何情况下，您同意快代理对本服务条款所承担的赔偿责任总额不超过向您收取的当次服务费用总额。</p>

                    <h2 id="toc_9">9、 完整协议</h2>
                    <p>9.1 本服务条款由本服务条款与本网站公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本服务条款为准。</p>
                    <p>9.2 本服务条款的章节标题仅为行文方便而设，不具有法律或合同效力。</p>
                    <p>9.3 您对本服务条款理解和认同，您即对本服务条款所有组成部分的内容理解并认同，一旦您使用本服务，您和快代理即受本服务条款所有组成部分的约束。</p>
                    <p>9.4 本服务条款部分内容被有管辖权的法院认定为违法的或被认定无效、被撤销的，不因此影响其他内容的效力。</p>

                    <h2 id="toc_10">10、商标、知识产权的保护</h2>
                    <p>10.1 除第三方产品或服务外，本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、架构、页面设计，均由快代理或快代理关联企业依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</p>
                    <p>10.2 非经快代理或快代理关联企业书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站上程序或内容。</p>
                    <p>10.3 尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</p>

                    <h2 id="toc_11">11、法律声明</h2>
                    <p>如果您使用kuaidaili.com服务，您的使用行为将被视为对本声明全部内容的认可。 “kuaidaili.com”指由积善科技（北京）有限公司（简称“快代理”）运营的网站，域名为kuaidaili.com。任何单位或个人认为kuaidaili.com网页内容可能涉嫌侵犯其合法权益，应该及时向快代理提出书面权利通知，并提供身份证明、权属证明、具体链接（URL）及详细侵权情况证明。快代理在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。</p>

                    <h2 id="toc_12">12、免责声明</h2>
                    <p>免费代理频道(https://www.kuaidaili.com/free/)发布的免费代理是第三方代理服务器，收集自互联网，并非快代理所有，快代理不对免费代理的有效性负责。 请合法使用免费代理，由用户使用免费代理带来的法律责任与快代理无关。 若免费代理侵犯了您的权益，请通过客服及时告知，快代理将在第一时间删除。</p>

                    <h2 id="toc_13">13、通知送达</h2>
                    <p>13.1 您理解并同意，快代理可依据自行判断，通过网页公告、电子邮件、手机短信或常规的信件传送等方式向您发出通知，且快代理可以信赖您所提供的联系信息是完整、准确且当前有效的；上述通知于通知到达您在注册时或更新后的联系方式或系统中（包括但不限于电话、微信、邮箱等）时视为已送达收件人。若联系方式有变更请您及时通知快代理并及时更新。</p>
                    <p>13.2 除非本服务条款另有约定或快代理与您另行签订的协议明确规定了通知方式，您发送给快代理的通知，应当通过快代理对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>

                    <h2 id="toc_14">14、 法律适用与管辖</h2>
                    <p>本服务条款之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本服务条款产生之争议，均应依照中华人民共和国法律予以处理，并提交北京市海淀区人民法院审判。</p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'UserAgreement',
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        // 打开预约课程弹框
        open() {
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false;
        }
    }
}
</script>
<style lang="less">
#tu-user-agreement {
    .contract {
        margin: 0 auto;
        line-height: 1.8;

        .head {
            position: relative;
        }

        .update_time {
            margin: 15px auto 10px;
            line-height: normal;
            color: #aaa;
        }

        .main {

        }

        .bold {
            font-weight: bold;
        }

        .underline {
            text-decoration: underline;
        }
    }
}
</style>