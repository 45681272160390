<template>
    <div id="tu-privacy-agreement">
        <el-dialog v-model="dialogVisible" title="隐私协议" destroy-on-close fullscreen>
            <div class="contract">
                <div class="head">
                    <h1 id="toc_0">快代理隐私保护政策</h1>
                    <p class="update_time">更新时间：2022-04-08</p>
                </div>
                <div class="main">
                    <p>感谢您选择和使用快代理服务。</p>
                    <p>为方便您注册、登录、使用相关服务，以及方便我司为您提供更加个性化的用户体验和服务，您在使用快代理服务时，我们可能会收集和使用您的相关信息。快代理非常重视用户信息的保护，您在快代理注册、使用等过程中形成的特定化信息，我司将严格按照中华人民共和国的相关法律法规的要求，采取相应的安全保护措施保护您的隐私信息。我们希望通过本《快代理隐私保护政策》（以下简称“本政策”）向您说明，在您使用快代理提供的产品、服务时，我们如何收集、使用、存储和特定情况下向第三方提供您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方法。</p>
                    <p class="bold underline">本政策与您所使用的快代理服务息息相关，希望您仔细阅读。您以在线勾选等任何方式确认接受本政策或使用快代理服务的同时，即视为您已充分阅读、理解并同意接受本政策的约束，本政策即在您与快代理之间产生法律效力。</p>

                    <h2 id="toc_1">1. 我们收集的信息</h2>
                    <p>1.1 我们可能通过以下几种方式收集您的信息：</p>
                    <p>1.1.1您主动向我司或我司官网页面或我司应用中包含的第三方服务商提交的信息，例如您或您授权的主体在注册账号、完善信息等情况下填写、上传的相应信息。</p>
                    <p>1.1.2后台自动记录的信息，我们在您使用我们的服务过程中以主动收集或产生相应的记录等方式收集的信息，例如在您登录快代理账号时后台记录下的登录时间，您使用特定快代理服务的操作记录等。</p>
                    <p>1.2 为完成快代理账号的注册，您须向我司进行实名认证，您需要根据您选择的注册方式提交真实、合法、有效的信息，设置、确认登录密码等，包括但不限于<span class="bold">微信号码、QQ 号码、电子邮箱、手机号码信息等。</span></p>
                    <p>1.3 如果您使用其他平台的账号登录快代理平台或将其他平台的账号与快代理账号进行关联、绑定，您须对该关联进行同意后方可进行快代理服务账号的注册或使用，经我司审查，若无相应授权，我司有权拒绝提供相应服务，若您已实际使用快代理服务，则视为您同意快代理通过关联平台获得您注册、使用过程中所需相关信息，您将授权快代理获得您其他平台账号的相关信息，例如包括但不限于 <span class="bold">微信号码、QQ 号码、邮箱地址、手机号码、昵称、头像、性别等。</span></p>
                    <p>1.4 在您进行实名认证时，您需要根据您所选择的认证类型提供对应的信息，例如<span class="bold">姓名（或名称）、公民身份证、护照、驾驶证</span>等有效个人证件或<span class="bold">营业执照</span>、提供服务所需的个人账号或对公账号、<span class="bold">通讯地址、手机号、人脸信息、联系方式等。</span></p>
                    <p>实名认证是建立账号归属以及提供相应产品和服务的重要依据，为了您的账号安全，请务必审慎选择认证方式，提交真实、合法、有效的信息。</p>
                    <p>我们的产品服务、活动等仅支持实名认证用户使用或参与，若您未进行实名认证，将无法使用或参与前述服务和活动，包括但不限于购买产品、续费升级、开具发票、申请为推广大使等。</p>
                    <p>1.5您在注册及使用过程中提交的信息与您后续管理、使用快代理账号、享受快代理服务以及参与快代理相关活动等密切相关，请您务必谨慎确认、维护及保管，若因您个人维护保管不善导致的信息泄所产生的损失（包括但不限于无法使用快代理账号、无法享受快代理服务以及无法产于快代理活动以及其他经济损失等）将由您自行承担，我司概不负责；前述信息发生变化时，请及时进行更改，例如发生管理人员变动时，应提前对账号密码、联系人、联系电话等进行变更、添加或与我司工作人员联系进行修改。</p>
                    <p>1.6我司向您提供浏览、搜索等基本服务，为识别帐号异常状态、了解产品适配性，维护基础功能的正常运行，在您使用快代理服务期间我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括但不限于：</p>
                    <p>1.6.1 操作记录，我们会接收并记录您使用快代理服务的操作记录及相关信息，例如<span class="bold">身份 ID、操作时间、操作的对象信息（订单ID、所属产品、提取情况）等。</span></p>
                    <p>1.6.2 开票信息，如果您需要申请开具发票，为开具及送达发票所必需，您应提供 <span class="bold">姓名或名称、公民身份号码或纳税人识别号、注册号等、开户银行及账号、公司注册地址或个人户籍地址等、公司电话以及发票接收人邮寄信息、联系人姓名、联系方式、邮寄地址等必要信息。</span></p>
                    <p>1.6.3 日志信息，我们可能会自动收集您对我们官网服务的详细使用情况，作为有关网络日志保存。例如您的<span class="bold">登录帐号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、停留时长等。</span></p>
                    <p>请注意，单独的日志信息是无法识别特定主体身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
                    <p>1.6.4 客服支持，您在通过在线客服向我们请求产品方面的技术帮助或了解我们产品的信息时，向我们提供的个人或产品相关信息，包括但不限于您的注册信息、产品需求、对我司产品和服务提出的意见和建议等，为提升您的用户满意度，您的意见我司将会认真考虑采纳，在后续提供产品或服务时加以完善。</p>
                    <p>1.6.5 购买消费，您在进行产品的购买、续费、升级时，需要提供给我们的信息，这些信息包括您企业的银行账户信息或个人的交易信息。</p>
                    <p>1.6.7 其他信息，例如工单记录、咨询沟通记录等。</p>
                    <p>1.7 如果您需要加入推广员合作计划，需另行签署相关协议，为履行合同所必需，您应提供真实、有效的联系人信息，包括但不限于联系人姓名、联系方式等，我司承诺非工作需要不打扰该联系人。</p>

                    <h2 id="toc_2">2. 我们如何使用 Cookie 及相关技术</h2>
                    <p>2.1 我们可能会通过 Cookie 和其他相关技术收集和使用您的信息。我们使用 Cookie 的具体用途包括：</p>
                    <p>2.1.1 记住您的身份。例如：Cookie 有助于我们辨认您作为我们的注册用户的身份。</p>
                    <p>2.1.2 分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括但不限于定制化页面、推荐等。</p>
                    <p>2.2 您可以通过浏览器设置拒绝或管理 Cookie。但请注意，如果停用 Cookie，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响，若您仍停用或拒绝使用Cookie，从而无法获得最佳用户体验，也视为认可并同意我司的所提供的服务。</p>
                    <p>2.3 通过快代理 Cookie 记录的有关隐私信息，将适用本政策。</p>

                    <h2 id="toc_3">3. 我们如何使用收集的信息</h2>
                    <p>为了向您提供优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：</p>
                    <p>3.1 账户注册：您注册时所提供的信息将用于创建您的快代理帐户，并且在您联系快代理客服时，这些信息还将协助为您提供技术支持。</p>
                    <p>3.2 产品购买：在您购买快代理的产品或服务时，我们将使用您的信息来处理付款并将购买信息发送给您。</p>
                    <p>3.3 产品使用：我们将产品使用中收集的信息用于验证您对产品或服务的使用权利以及服务体验，以便我司提升服务质量。</p>
                    <p>3.4 内部分析：我们会使用您提供给我们的信息进行内部统计和分析，从而评估并增强您对快代理网站和产品服务的体验，包括但不限于通过识别您的喜好和购买意向，进行营销以及与运营和开发相关的活动。</p>
                    <p>3.5 服务优化和开发：例如，我们会根据系统响应您的需求时所产生的信息，优化我们的服务。例如，为履行本政策或您使用的其他相关产品、服务的服务协议、规则等的约定。</p>
                    <p>3.6保护快代理、快代理用户和快代理推广大使的权益。例如，我们会将您的信息用于身份验证、安全防范、诈骗监测，以及判断您的账号安全等用途。例如，您在使用安全功能或其他类似服务时，我们会对您注册使用过程中遇到的恶意程序或病毒进行检测，或为您识别诈骗信息。在处理相关纠纷、投诉时，为相关当事人提供有关信息以便更好的促进纠纷、投诉的处理、解决等。</p>
                    <p>3.7向您推送相关业务消息（如账号安全消息、产品消息、平台动态等）和营销活动。例如，快代理可以通过您的手机号码、电子邮箱等联系方式以短信、电话、电子邮件等任何方式向您推送安全预警、营销活动等通知服务。</p>
                    <p>您在注册快代理账号时将授权我司向您推送业务相关消息，您可通过“会员中心-消息接收管理”页面自行关闭安全预警相关业务除外的消息推送服务。</p>
                    <p>3.8 邀请您参与有关我们产品和服务的调查。</p>
                    <p>3.9 其他可能需要使用收集的信息的相关场景。</p>

                    <h2 id="toc_4">4. 反滥用自动收集信息</h2>
                    <p>4.1禁止任何用户使用快代理的产品或服务进行任何违法犯罪活动。但是为规避可能存在的风险，快代理会自动记录并接收您的使用记录等信息，只用于判断是否存在非法用途和滥用，不做其他用途。使用记录包括：</p>
                    <p>（1）日期和时间；</p>
                    <p>（2）连接状态；</p>
                    <p>（3）协议类型；</p>
                    <p>（4）客户端软件版本号；</p>
                    <p>（5）您请求的网页URL记录、Cookie等信息；</p>
                    <p>（6）其他相关信息。</p>

                    <h2 id="toc_5">5. 我们共享、转让的信息</h2>
                    <p>5.1 快代理不会在未经您同意或授权的情况下将您的个人信息提供给第三方。但是，经您确认同意，我们可在以下情况下共享您的个人信息：</p>
                    <p>（1）在获取同意的情况下共享：获得您的明确同意后，快代理会与其他方共享您的个人信息。</p>
                    <p>（2）共享给授权合作伙伴：我们会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们向您寄送发票（如有）时，必须与物流服务提供商共享您的个人信息才能安排寄送。我们仅会出于特定、明确且合法的目的处理您的个人信息，并且只会共享提供服务所必要的信息。同时，我们会与合作伙伴或须使用信息的第三方签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
                    <p>（3）在法定情形下的共享：我们会根据法律法规规定、以及行政司法机关的公文的要求，对特定行政司法机关提供其要求的信息。</p>
                    <p>5.2 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
                    <p>（1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
                    <p>（2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的将持有您个人信息的公司、组织或者个人继续受此隐私政策的约束，否则我们将要求该公司、组织或个人重新向您征求授权同意，在未获得您明确同意的情况下，我司有权拒绝转移个人信息。</p>
                    <p>5.3 第三方SDK类服务商：我们官网页面中会包含第三方SDK，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您为使用以及使用过程中所须的信息（包括但不限于以嵌入代码、插件等形式）。</p>
                    <p>点击查看：<a href="/third_sdk_contract/" target="_blank">快代理第三方SDK列表</a></p>
                    <p>前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。但我们也会努力审查该第三方的业务准入资质并努力要求该服务商在信息安全保护方面的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这类SDK或其他类似的应用程序存在信息泄露风险时，建议您立即终止相关操作并及时与我们取得联系，非因我方原因引起的隐私信息泄露风险及后果，我司不承担责任，我司可以配合您积极维护相应信息安全及权利。</p>
                    <p>5.4 根据相关法律等规定，在以下情形中，我们可以不经您同意而共享、转让、公开披露您的信息：</p>
                    <p>5.4.1 履行法律法规规定的义务相关的，例如执行有权机关的要求。</p>
                    <p>5.4.2 与国家安全、国防安全直接相关的。</p>
                    <p>5.4.3 与公共安全、公共卫生、重大公共利益直接相关的。</p>
                    <p>5.4.4 与刑事侦查、起诉、审判和判决执行等直接相关的。</p>
                    <p>5.4.5 出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的。</p>
                    <p>5.4.6 用户自行向社会公众公开的个人信息。</p>
                    <p>5.4.7 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                    <p>5.4.8其他。</p>

                    <h2 id="toc_6">6. 我们会如何保护和保存您的信息</h2>
                    <p>6.1快代理重视个人信息的安全。我们采取互联网业内标准做法来保护您的个人信息，防止信息遭到未经授权访问、披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。</p>
                    <p>6.2 我们会采取一切合理可行的措施，确保不收集与快代理提供的服务无关的个人信息。我们会尽全力保护已收集到的您的个人非公开信息，但请您理解，由于技术的限制以及存在的各种恶意手段，在互联网行业，不会始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有几率因我们可控范围外的因素而出现信息泄露风险。</p>
                    <p>6.3 若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等法律法规的要求及时上报公司及相关部门，并以发送电子邮件、推送通知、公告等形式告知您相关情况，并向您给出安全处理建议。</p>
                    <p>6.4 为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识和采取相应信息保护措施。我们仅在因快代理过错直接导致您信息泄露的范围内承担责任，请您妥善保管您的账号及密码信息，避免您的信息泄露。除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等信息。</p>
                    <p>6.5 我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                    <p>6.6 我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。</p>
                    <p>6.7 我们通常仅会在达到本政策所述目的所必需的时限内保存您的信息，保存期限不会超过满足相关使用目的所必需的期限。但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：</p>
                    <p>6.7.1 遵守适用的法律法规等有关规定。</p>
                    <p>6.7.2 遵守法院判决、裁定或其他法律程序的要求。</p>
                    <p>6.7.3 遵守相关行政、司法机关或其他有权机关的要求。</p>
                    <p>6.7.4 为执行相关服务协议或本政策、处理投诉或纠纷，或者是保护他人的人身和财产安全或合法权益所合理必需的。</p>
                    <p>6.7.5其他。</p>

                    <h2 id="toc_7">7. 有害信息的过滤和删除</h2>
                    <p>快代理禁止用户利用产品或服务发布一切有害信息，包括：</p>
                    <p>（1）违反中国宪法确定的基本原则的；</p>
                    <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                    <p>（3）损害国家荣誉和利益，攻击党和政府的；</p>
                    <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                    <p>（5）破坏国家、地区间友好关系的；</p>
                    <p>（6）违背中华民族传统美德、社会公德、论理道德、以及社会主义精神文明的；</p>
                    <p>（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                    <p>（8）散布谣言或不实消息，扰乱社会秩序 ，破坏社会稳定的；</p>
                    <p>（9）煽动、组织、教唆恐怖活动、非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
                    <p>（10）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
                    <p>（11）侮辱或诽谤他人，侵害他人合法权益的；</p>
                    <p>（12）侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；</p>
                    <p>（13）使用漫骂、辱骂、中伤、恐吓、诅咒等不文明语言的；</p>
                    <p>（14）以非法民间组织名义活动的；</p>
                    <p>（15）侵犯他人著作权、信息网络传播权等合法权益的；</p>
                    <p>（16）含有法律、行政法规禁止的其他内容的。</p>
                    <p>（17）其他违反法律法规、社会道德风尚的。</p>
                    <p></p>
                    <p>如用户在使用快代理的过程中不能履行和遵守协议中的规定，快代理有权对违反协议的用户做出关闭帐号处理，若因用户发布上述不当言论或行为给快代理造成损失的，快代理有权追究当事人的法律责任，要求其赔偿快代理损失，并承担快代理在维护权益过程中所支出的一切费用，包括但不限于诉讼费、鉴定费、律师费等。此外，快代理在采取相应措施后，不为此向违反协议的用户承担违约责任或其他法律责任。快代理依据本协议约定获得处理违法违规内容的权利，该权利不构成快代理的义务或承诺，快代理不能保证总能及时发现违法行为或进行相应处理，快代理承诺会在第一时间采取相应措施。</p>

                    <h2 id="toc_8">8. 未成年人的特别注意事项</h2>
                    <p>如果您不是具备完全民事行为能力的民事主体，您无权使用kuaidaili.com服务，因此快代理希望您不要向我们提供任何个人信息。</p>

                    <h2 id="toc_9">9. 您如何管理自己的信息</h2>
                    <p>9.1 您可以访问、修改和删除您的用户信息。为保障您的账号安全、合法权益等，不同的信息访问、修改和删除可能有不同的要求（例如变更实名认证信息需要进行主体变更认证），并且，基于技术逻辑、法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除（例如您无法更改分配的账号 ID）。</p>
                    <p>9.2 在您注册完成后，您可以对账号信息进行完善、修改等，例如完善代理用途信息可为您提供更精准的产品规格及优惠。</p>
                    <p>9.3 您可以修改消息通知设置，选择接收某一类型消息或者选择不接收某一类别的消息。但由于推送的消息可能与您的账号安全、服务使用等息息相关（例如订单欠费通知、产品使用告警通知等），我们建议您不要取消接收，避免遗漏消息。</p>
                    <p>9.4 我们将依照法律法规要求或双方约定收集、使用和存储您的信息，如我们违反法律法规的规定或者双方的约定收集、使用或存储您的信息，我们将进行更正或删除。</p>
                    <p>9.5 如果我们收集、存储的您的信息有错误，且您无法自行更正的，您可以要求我们进行更正。</p>
                    <p>9.6 您可以注销快代理账号。我们为您提供快代理账号注销的途径，您可以通过在线申请注销或联系我们的客服或通过其他我们公示的方式申请注销您的账号。 在您注销快代理账号后，您将无法再以此账号登录和使用快代理相关产品与服务；该账号在快代理下的产品与服务使用期间已产生的但未消耗完毕的权益及未来的预期利益等全部权益将被清除；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外）；同时，快代理账号一旦注销完成，将无法恢复。</p>
                    <p>9.7 如您在谨慎考虑后仍执意决定注销您的快代理账号的，您可以在我们官网的会员中心-账号设置页面下，根据操作指引向我们提交注销申请。</p>

                    <h2 id="toc_10">10. 本政策如何更新及适用范围</h2>
                    <p>本政策为<a href="javascript:;" target="_blank">《快代理用户服务协议》</a>的重要组成部分。我们可能适时根据相关法律规定以及有关部门的规定修订本政策的条款，该修订构成本政策的一部分。如果，前述修订可能造成您在本政策下的权利的实质性减少，或扩大我们收集、使用信息的范围等重要规则的变更，我们将在修订生效前通过在快代理网站上显著位置提示或向您发送电子邮件或以其他方式通知您，请仔细阅读确认是否同意新的政策。自我司向您注册时提供的联系方式（包括但不限于QQ、微信、邮箱等）或者您能够通过公开方式获取到新的政策情况下，您未向我司明确提出异议并继续使用，若您继续使用我们的服务，即表示您同意接受修订后的内容。</p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'PrivacyAgreement',
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        // 打开预约课程弹框
        open() {
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false;
        }
    }
}
</script>
<style lang="less">
#tu-privacy-agreement {
    .contract {
        margin: 0 auto;
        line-height: 1.8;

        .head {
            position: relative;
        }

        .update_time {
            margin: 15px auto 10px;
            line-height: normal;
            color: #aaa;
        }

        .main {

        }

        .bold {
            font-weight: bold;
        }

        .underline {
            text-decoration: underline;
        }
    }
}
</style>